<template>
    <div class="animated fadeIn">
        <b-card no-body>
            <b-card-header>
                <i class="icon-note"></i> Add Article
                <div class="card-header-actions">
                    <a class="card-header-action" href="article" rel="noreferrer noopener">
                        <small class="text-muted">Back</small>
                    </a>
                </div>
            </b-card-header>
            <b-card-body>
                <div class="loading" v-if="isLoading == true">
                    <div class="sk-three-bounce">
                        <div class="sk-child sk-bounce1"></div>
                        <div class="sk-child sk-bounce2"></div>
                        <div class="sk-child sk-bounce3"></div>
                    </div>
                </div>
                <b-row>
                    <b-col sm="12">
                        <!--<h6>Simple Form</h6>-->
                        <b-form v-on:submit.prevent="onSubmit" novalidate action="https://vuejs.org/">
                            <b-form-group id="titleInputGroup1"
                                            label="Title"
                                            label-for="title">
                                <b-form-input id="title"
                                            type="text"
                                            v-model.lazy.trim="$v.form.title.$model"
                                            :state="chkState('title')"
                                            aria-describedby="titleFeedback"
                                            placeholder="Please Enter Article Title"
                                            autofocus />
                                <b-form-invalid-feedback id="titleFeedback" v-for="(error , index) in errors.form.title" :key="index">
                                - {{ error }} <br>
                                </b-form-invalid-feedback>
                            </b-form-group>
                            <b-form-group id="contentInputGroup2"
                                            label="Content"
                                            label-for="content">
                                <quill-editor id="content"
                                            style="margin-bottom:5px"
                                            v-model.lazy.trim="$v.form.content.$model"
                                            :state="chkState('content')"
                                            autocomplete='family-name'>
                                </quill-editor>
                                <div  v-if="this.form.content == '' " >
                                <p id="contentFeedback" v-for="(error , index) in errors.form.content" :key="index">
                                    - {{ error }} <br>
                                </p>
                                </div>
                            </b-form-group>
                            <b-form-group id="imageInputGroup3"
                                            label="Image"
                                            label-for="image">
                                <b-form-file id="selectedImage"
                                            :plain="true"
                                            accept="image/*"
                                            aria-describedby="imageFeedback"
                                            v-model.lazy.trim="$v.form.selectedImage.$model"
                                            :state="chkState('selectedImage')"
                                            @change="onFileSelected">
                                </b-form-file>
                                <b-form-invalid-feedback id="imageFeedback" v-for="(error , index) in errors.form.image" :key="index">
                                - {{ error }} <br>
                                </b-form-invalid-feedback>
                            </b-form-group>
                            <b-form-group id="typeInputGroup1"
                                            label="Category"
                                            label-for="category">
                                <b-form-select id="category"
                                            :plain="true"
                                            v-model.lazy.trim="$v.form.category.$model"
                                            :state="chkState('category')"
                                            :options="[{value: '', text:'Please select'},
                                                                {value: 'News', text: 'News'},
                                                                {value: 'Promo', text: 'Promo'},
                                                                {value: 'Maintenance', text: 'Maintenance'}]"
                                            aria-describedby="categoryFeedback"
                                            autofocus />
                                <b-form-invalid-feedback id="categoryFeedback" v-for="(error , index) in errors.form.category" :key="index">
                                - {{ error }} <br>
                                </b-form-invalid-feedback>
                            </b-form-group>
                            <b-form-group id="slugInputGroup1"
                                            label="Slug"
                                            label-for="slug">
                                <b-form-input id="slug"
                                            type="text"
                                            :class="{ 'is-invalid' : $v.form.slug.$errors, 'is-valid' : !$v.form.slug.$invalid}"
                                            v-model.lazy.trim="$v.form.slug.$model"
                                            :state="chkState('slug')"
                                            aria-describedby="slugFeedback"
                                            placeholder="Please Enter Article slug"
                                            autofocus />
                                <b-form-invalid-feedback id="slugFeedback" >
                                <span v-if="!$v.form.slug.required">- Slug artikel tidak boleh kosong!</span>
                                <span v-if="!$v.form.slug.isUnique">- Slug artikel tidak boleh sama dengan data sebelumnya!</span>
                                </b-form-invalid-feedback>
                            </b-form-group>
                            <b-button type="submit" variant="primary">
                                Submit
                            </b-button>
                        </b-form>
                    </b-col>
                </b-row>
            </b-card-body>
        </b-card>
    </div>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required } from "vuelidate/lib/validators"

export default {
  name: "store-article",
  data() {
    return {
        form: {
            title: '',
            content: '',
            category: '',
            selectedImage: '',
            slug: ''
        },
        errors: {
            message: [],
            code: '',
            status: [],
            headers: [],
            form: {
                title: [],
                content: [],
                image: [],
                category: [],
                slug: []
            },
            isLoading: false
        },
    }
  },
    computed: {
        formStr() { return JSON.stringify(this.form, null, 4) },
        isValid() { return !this.$v.form.$anyError },
        isDirty() { return this.$v.form.$anyDirty },
        invCheck() { return 'You must accept before submitting' },
    },
    mixins: [validationMixin],
    validations: {
        form: {
            title: {
                required,
            },
            category: {
                required
            },
            content: {
                required
            },
            selectedImage: {
                required
            },
            slug: {
                required,
                isUnique (value) {
                if (value === '') return true

                return new Promise ((resolve, reject) => {
                    setTimeout(() => {

                    this.$http.get(`article-slug/${value}`)
                    .then((success) => {
                        if (success.data == "") {
                        resolve(true)
                        return true
                        }else{
                        reject(false)
                        return false
                        }
                    }).catch((error) => {
                        if (error.response) {
                            this.errors.code = error.response.status;
                            this.errors.status = error.response.data.meta.code;
                            this.errors.headers = error.response.headers;
                        }
                    })
                    }, 350 + Math.random() * 300)
                })
                }
            }
        }
    },
    methods: {
        onFileSelected(event) {
            this.form.selectedImage = event.target.files[0];
        },
        onSubmit() {
            this.validate()
            this.isLoading = true
            const img =  new FormData();
            if (this.form.selectedImage == '') {
                img.append('image', this.form.selectedImage = '')
                img.append('title', this.form.title)
                img.append('content', this.form.content)
                img.append('category', this.form.category)
                img.append('slug', this.form.slug)
            }else{
                img.append('image', this.form.selectedImage, this.form.selectedImage.name)
                img.append('title', this.form.title)
                img.append('content', this.form.content)
                img.append('category', this.form.category)
                img.append('slug', this.form.slug)
            }


            this.$http.post(`store-articles`, img)
            .then(() => {
                this.isLoading = false
                this.$router.push("/article");
                this.$toasted.success('Article successfully created!')
            }).catch((error) => {
                if (error.response) {
                this.isLoading = false
                this.errors.code = error.response.status;
                this.errors.status = error.response.data.meta.code;
                this.errors.headers = error.response.headers;
                if(this.errors.status == 422) {
                    this.$toasted.error('Please fill out the form that must be required')
                    this.errors.message = error.response.data.meta.message;
                    this.errors.form.title = this.errors.message.title;
                    this.errors.form.content = this.errors.message.content;
                    this.errors.form.image = this.errors.message.image;
                    this.errors.form.category = this.errors.message.category;
                    this.errors.form.type = this.errors.message.type;
                    this.errors.form.slug = this.errors.message.slug;
                }
                }
            })
        },
        chkState(val) {
            const field = this.$v.form[val]
            return !field.$dirty || !field.$invalid
        },
        findFirstError(component = this) {
            if (component.state === false) {
                if (component.$refs.input) {
                component.$refs.input.focus()
                return true
                }
                if (component.$refs.check) {
                component.$refs.check.focus()
                return true
                }
            }
            let focused = false
            component.$children.some((child) => {
                focused = this.findFirstError(child)
                return focused
            })

            return focused
        },
        validate() {
            this.$v.$touch()
            this.$nextTick(() => this.findFirstError())
            return this.isValid
        }
    }
}
</script>

<style scoped>
.btn.disabled {
  cursor: auto;
}
.preview {
  background-color: white;
  border: 1px solid #DDD;
  padding: 5px;
  width: 50%;
  height: 50%;
}
.image-preview {
  padding: 20px;
}

.invalid-feedback {
    font-size: 100% !important;
}
.notValidate {
  border: #f86c6b 1px solid;
}
.validate {
  border: green 1px solid;
}
#contentFeedback {
  color: #f86c6b !important;
  font-size: 100%;
}

</style>
<style>
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
}

</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />
